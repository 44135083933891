import { addNamespace } from '@Utils/actions';
import { Pagination } from '@innovyze/lib-am-common';
import { createAction } from '@reduxjs/toolkit';

export const listCohorts = createAction(addNamespace('cohorts/list'));

export const listCohortsResolved = createAction<Pagination>(
	addNamespace('cohorts/listResolved'),
);

export const listCohortsRejected = createAction(
	addNamespace('cohorts/listRejected'),
);
