import { createReducer } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { placeholderAction } from '@Actions/util.actions';
import { UtilStoreState } from '@Types/utils.types';

export const initState: UtilStoreState = {
	placeholder: undefined,
};

export interface UtilReducer {
	[x: string]: (
		state: UtilStoreState,
		action: AnyAction,
	) => UtilStoreState | undefined;
}

export const reducer: UtilReducer = {
	[placeholderAction.toString()]: state => ({
		...state,
	}),
};

export default createReducer(initState, reducer);
