import CohortsStore, { initState as CohortsInitState } from './Cohorts.reducer';
import utilStore, { initState as utilInitState } from './util.reducer';

import { StoreState } from '@Types';
import { combineReducers } from 'redux';
import { moduleNamespace } from '@Store/config.store';

export const initialState: StoreState = {
	CohortsStore: CohortsInitState,
	util: utilInitState,
};

export default combineReducers({
	CohortsStore,
	utilStore,
});

export const reducerKey = moduleNamespace;
