import { useGlobalization } from '@Translations/useGlobalization';
import { FullPage, FullPageWrapper } from '@Utils/styles';
import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux';

export const CohortsList = () => {
	// const dispatch = useDispatch();
	const { t } = useGlobalization();

	const isLoading = false; //selectModelConfigsIsLoading();
	const isWaiting = false; //selectModelConfigsIsWaiting();
	const isCreating = false; //selectModelConfigIsCreating();

	useEffect(() => {
		// LOAD THE COHORTS
	}, []);

	return (
		<FullPageWrapper
			className={isLoading || isWaiting || isCreating ? 'wait' : ''}
			title={t('Cohorts')}
			applyPadding={true}
			headerUnderline>
			<FullPage>COHORTS</FullPage>
		</FullPageWrapper>
	);
};
export default CohortsList;
