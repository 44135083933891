import React from 'react';
// import { addRouteNamespace } from '@Utils/actions';
import LanguageIcon from '@mui/icons-material/Language';
import CohortsList from './CohortsList';

// NOTE WE DONT NEED TO TRANSLATE THESE AS MOST ARE NEVER SHOWN
export const mainPageRoutes = [
	// {
	// 	title: 'Cohorts',
	// 	to: addRouteNamespace('modelSetup/:configId'),
	// 	render: <Cohorts page={0} />,
	// },
];

export const miniPageRoutes = [
	{
		title: 'Mercury',
		to: '/mercury',
		icon: <LanguageIcon />,
		render: <CohortsList />,
	},
	...mainPageRoutes,
];

export default [mainPageRoutes, miniPageRoutes];
