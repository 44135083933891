import { createReducer } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import * as AM_COMMON from '@innovyze/lib-am-common/Reducers';
import {
	listCohorts,
	listCohortsRejected,
	listCohortsResolved,
} from '@Actions/Cohorts.actions';
import { CohortsStoreState } from '@Types/Cohorts.types';

export const initState: CohortsStoreState = {
	isLoading: false,
	isCreating: false,
	isDeleting: false,
	isRenaming: false,
	CohortsList: AM_COMMON.paginationInitialState,
};

interface CohortsReducer {
	[x: string]: (
		state: CohortsStoreState,
		action: AnyAction,
	) => CohortsStoreState | undefined;
}

export const reducer: CohortsReducer = {
	[listCohorts.toString()]: state => ({
		...state,
		isLoading: true,
	}),
	[listCohortsResolved.toString()]: (state, action) => ({
		...state,
		CohortsList: action.payload,
		isLoading: false,
	}),
	[listCohortsRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),
};

export default createReducer(initState, reducer);
